import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from "react-router-dom";
import AuthGuard from "../authen/AuthGuard";

const TransactionErrorComponent = ({ transactionType, details }) => {
    const navigate = useNavigate();

    // Redirect back to transfer page
    const handleRedirect = () => {
        navigate("/");
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minHeight: "100vh",
                backgroundColor: "transparent",
                position: "relative", // Position for the close button
                marginTop: "-100px",
            }}
        >
            {/* Error Icon */}
            <ErrorIcon
                sx={{
                    fontSize: "100px",
                    color: "red",
                    marginBottom: "20px",
                }}
            />

            {/* Error Message */}
            <Typography
                variant="h4"
                align="center"
                sx={{ fontWeight: "bold", color: "#4e2a84" }}
            >
                {transactionType} Unsuccessful!
            </Typography>

            <Typography
                variant="body1"
                align="center"
                sx={{ color: "#555", marginTop: "10px" }}
            >
                Your transaction were unsuccessful. Please view the error details below and try again.
            </Typography>

            <Typography
                variant="body2"
                align="center"
                sx={{ color: "#888", marginTop: "20px", whiteSpace: "pre-wrap" }}
            >
                {details}
            </Typography>

            <Box mt={3} textAlign="center">
                <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#4e2a84", color: "#fff" }}
                    onClick={handleRedirect}
                >
                    Try Again
                </Button>
            </Box>
        </Box>
    );
};

const TransactionError = ({ transactionType, details }) => (
    <AuthGuard>
        <TransactionErrorComponent
            transactionType={transactionType}
            details={details}
        />
    </AuthGuard>
);

export default TransactionError;