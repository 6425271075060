import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../authen/AuthGuard"; // Ensure correct path to AuthGuard
import axios from "axios";
import { oauthConfig } from "../config/oauthConfig";
import { isTokenExpired } from "../utils/authUtils";
import Loading from "./Loading";

const TransferComponent = ({ accounts, onTransfer, setTransactionType, setTransactionErrorDetails }) => {
  setTransactionType("Transfer");

  const [formData, setFormData] = useState({
    from_account_no: "",
    to_account_no: "",
    amount: "",
    description: "",
  });

  const token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(false); // For when transfer is processing
  const [transferDetails, setTransferDetails] = useState(""); // Details when transfer is processing
  const [openDialog, setOpenDialog] = useState(false); // State for popup dialog
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    if (!token || isTokenExpired(token)) {
      navigate('/logout');
    }
  }, [navigate, token]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTransferDetails(`Transferring an amount of ${formData.amount} from account ${formData.from_account_no} to account ${formData.to_account_no}`);
    setOpenDialog(true); // Open confirmation popup
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the popup
  };

  const handleConfirm = async () => {
    setOpenDialog(false); // Close the popup
    setLoading(true); // Transfer is processing

    try {
      const response = await axios.post(`${oauthConfig.transferService}`, formData, {
        headers: {
          Authorization: "Bearer " + token,
        }
      });

      setLoading(false);

      if (response.data.data.status == "POSTING_INSTRUCTION_BATCH_STATUS_REJECTED") {
        // Displaying contracts violation here
        const violationMessage = (violations) => {
          let violationMessage = '';

          const violationNames = Object.keys(violations);
          violationNames.forEach(violationName => {
            if (violations[violationName]) {
              try {
                const violation = JSON.parse(violations[violationName]);

                if (violation.length > 0) {
                  violationMessage += `${violationName}:\n`;
                  violation.forEach((violation, index) => {
                    violationMessage += `${index + 1}. Account ID: ${violation.account_id}\n`;
                    violationMessage += `Reason: ${violation.reason}\n`;
                    violationMessage += `Type: ${violation.type}\n\n`;
                  });
                }
              } catch (error) {
                console.error("Error parsing contract violations:", error);
              }
            }
          });
          return violationMessage;
        };

        setTransactionErrorDetails(`${violationMessage(response.data.data.violation)}`);

        navigate("/error");

        console.log("Transfer fails:", response.data);
      } else if (response.data.data.status == "POSTING_INSTRUCTION_BATCH_STATUS_ACCEPTED") {
        if (onTransfer) onTransfer(response.data); // Call the transfer handler if provided

        setFormData({ from_account_no: "", to_account_no: "", amount: "", description: "", }); // Reset the form

        navigate("/success");  // Navigate to the Success page

        console.log(`Transfer funds successfully for ID: ${response.data.data.id}`);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error transferring funds:", error);
      alert("There was an error transferring funds. Please try again.");
    }
  };

  if (!accounts || accounts.length === 0) {
    return (
      <Box sx={{ textAlign: "center", paddingTop: "50px" }}>
        <Typography variant="h6" color="error">
          No accounts available for transfer.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {loading
        ? <Loading transactionType="Transfer" details={transferDetails} />
        : <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            minHeight: "100vh",
            paddingTop: "50px",
          }}
        >
          <Box
            padding={4}
            style={{
              maxWidth: "500px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              backgroundColor: "#fff",
            }}
          >
            <Typography variant="h5" gutterBottom align="center" color="primary">
              Transfer Funds
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* Sender Account */}
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Sender Account"
                    name="from_account_no"
                    value={formData.from_account_no}
                    onChange={handleChange}
                    required
                  >
                    {accounts.map((account) => (
                      <MenuItem key={account.account_id} value={account.account_number}>
                        {account.account_type} ({account.account_number})
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Recipient Account */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Recipient Account Number"
                    name="to_account_no"
                    value={formData.to_account_no}
                    onChange={handleChange}
                    required
                    placeholder="Enter recipient's account number"
                  />
                </Grid>

                {/* Transfer Amount */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Amount"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    required
                    type="number"
                    placeholder="Enter amount to transfer"
                  />
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Add a note (optional)"
                  />
                </Grid>
              </Grid>

              {/* Submit Button */}
              <Box mt={3} textAlign="center">
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#4e2a84", color: "#fff" }}
                >
                  Transfer
                </Button>
              </Box>
            </form>
          </Box>

          {/* Dialog for Confirmation */}
          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle>Confirm Transfer</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to transfer <strong>{formData.amount}</strong>{" "}
                from account <strong>{formData.from_account_no}</strong> to{" "}
                <strong>{formData.to_account_no}</strong>?
              </Typography>
              {formData.description && (
                <Typography mt={2}>
                  <strong>Note:</strong> {formData.description}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary" variant="contained">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      }
    </>
  );
};

const Transfer = ({ accounts, transactions, setTransactionType, setTransactionErrorDetails }) => (
  <AuthGuard>
    <TransferComponent
      accounts={accounts}
      transactions={transactions}
      setTransactionType={setTransactionType}
      setTransactionErrorDetails={setTransactionErrorDetails}
    />
  </AuthGuard>
);

export default Transfer;
