import React, { useState, useEffect } from "react";
import { Box, Typography, List, ListItem, ListItemText, Button, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard
import axios from "axios";
import { oauthConfig } from "../config/oauthConfig";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../utils/authUtils";
const CloseAccountComponent = ({ accounts, onCloseAccount }) => {
  const navigate = useNavigate();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage popup visibility
  const [formData, setFormData] = useState({
    account_id: "",
  });

  const token = localStorage.getItem("access_token");
  useEffect(() => {
    if (!token || isTokenExpired(token)) {
      navigate('/logout');
    }
  }, [navigate, token]);

  const handleAccountSelection = (account) => {
    setSelectedAccount(account);
    setFormData({account_id: account.account_id});
  };

  const handleCloseAccount = async () => {
    if (onCloseAccount && selectedAccount) {
      try {
        const response = await axios.post(`${oauthConfig.closeAccountService}`, formData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
  
        if (response.status === 200 && response.data.message === "success") {
          // Update the account list by notifying the parent
          onCloseAccount(selectedAccount);
  
          // Reset state and close the dialog
          setDialogOpen(false);
          setFormData({ account_id: "" });
  
          // Show success message
          alert(`Closed account: ${response.data.data.account_number} (${response.data.data.account_status})`);
          console.log("Account successfully closed:", response.data.message);
        } else {
          // Handle unsuccessful responses
          setDialogOpen(false);
          alert(response.data.message || "Failed to close the account. Please try again.");
          console.error("Failed to close account:", response.data.message || "Unknown error");
        }
      } catch (error) {
        // Handle network or API errors
        setDialogOpen(false);
        alert("An error occurred while closing the account. Please try again later.");
        console.error("Error occurred while closing account:", error);
      }
    }
  };
  

  const handleOpenDialog = () => {
    if (selectedAccount) {
      setDialogOpen(true); // Show the confirmation dialog
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog without action
  };

  if (!accounts.length) {
    return (
      <Box textAlign="center" padding={4}>
        <Typography variant="h6">No accounts available to close.</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "100vh",
        paddingTop: "50px",
      }}
    >
      <Box
        padding={2}
        sx={{
          maxWidth: "500px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "#fff",
          flex: 1, // Allow content to take available space
          overflowY: "auto", // Allow scrolling inside the content area
        }}
      >
        <Typography variant="h5" gutterBottom align="center" color="primary">
          Settings Account
        </Typography>
        <List>
          {accounts
            .filter((account) => account.account_type === "Savings" && account.account_status === "ACCOUNT_STATUS_OPEN")
            .map((account) => (
              <React.Fragment key={account.account_id}>
                <ListItem
                  button
                  selected={selectedAccount?.account_id === account.account_id}
                  onClick={() => handleAccountSelection(account)}
                  sx={{
                    border: selectedAccount?.account_id === account.account_id ? "2px solid #4e2a84" : "none",
                    borderRadius: "8px",
                    mb: 1,
                  }}
                >
                  <ListItemText
                    primary={account.type}
                    secondary={`Account No: ${account.account_number} | Balance: ${account.balance}`}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
        </List>
        {selectedAccount && (
          <Box textAlign="center" mt={4}>
            <Typography variant="body1" gutterBottom>
              Selected Account: {selectedAccount.type} ({selectedAccount.account_number})
            </Typography>
          </Box>
        )}
      </Box>

      {/* Fixed Close Account Button */}
      {selectedAccount && (
        <Box
          sx={{
            position: "fixed",
            bottom: "80px", // Space for footer
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%", // Ensure the button is within bounds
            maxWidth: "500px", // Optional: max width for button container
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={handleOpenDialog}
            // sx={{
            //   width: "100%", // Button takes full width of the container
            // }}
          >
            Close Account
          </Button>
        </Box>
      )}

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Account Closure</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close the account: <strong>{selectedAccount?.type}</strong> (
            {selectedAccount?.account_number})?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseAccount} variant="contained" color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Wrap CallbackComponent with AuthGuard and export it
const CloseAccount = ({ accounts, onCloseAccount }) => (
  <AuthGuard>
    <CloseAccountComponent accounts={accounts} onCloseAccount={onCloseAccount} />
  </AuthGuard>
);

export default CloseAccount;
