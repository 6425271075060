import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { oauthConfig } from "../config/oauthConfig";
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard
import { isTokenExpired } from "../utils/authUtils";
import { useNavigate } from "react-router-dom";

const CreateSubAccountComponent = ({ selectedAccount, products, onCreate, setPockets, setAccounts }) => {
  const [formData, setFormData] = useState({
    parent_account_id: selectedAccount.account_id,
    product_id: "",
    pocket_name: "",
  });

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();

  // Fetch product list when the component loads
  useEffect(() => {
    if (!token || isTokenExpired(token)) {
      navigate('/logout');
    }
  }, [navigate, token]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true); // Show the popup on form submission
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      // Make API call to create account
      const response = await axios.post(`${oauthConfig.createSubAccountService}`, formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (onCreate) {
        onCreate(response.data.data);
      }

      if (setAccounts) {
        setAccounts((prevAccounts) => [...prevAccounts, response.data.data]);
      }

      if (setPockets) {
        setPockets((prevPockets) => [...prevPockets, response.data.data
          //   {
          //   account_id:response.data.data.account_id,
          //   account_number:response.data.data.account_number,
          //   account_status:response.data.data.account_status,
          //   account_type:response.data.data.account_type,
          //   balance:response.data.data.balance,
          //   created_at:response.data.data.created_at,
          //   opening_date:response.data.data.opening_date,
          //   parent_account_id:response.data.data.parent_account_id,
          //   pocket_status:response.data.data.pocket_status,
          //   updated_at:response.data.data.updated_at,
          // }
        ])
      }

      // Reset the form
      setFormData({ product_id: "", pocket_name: "", parent_account_id: "" });
      setOpen(false);
    } catch (error) {
      console.error("Failed to create account:", error);
      alert("There was an error creating the account. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the popup without submitting
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          paddingTop: "50px",
        }}
      >
        <Box
          padding={2}
          style={{
            maxWidth: "500px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "2px",
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="h5" gutterBottom align="center" color="primary">
            Create New Pocket
          </Typography>
          <form onSubmit={handleSubmit}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Parent Account Number"
                  name="parent_account_no"
                  value={selectedAccount?.account_number || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Pocket Name"
                  name="pocket_name"
                  value={formData.pocket_name}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="product-label">Select Product</InputLabel>
                  <Select
                    labelId="product-label"
                    name="product_id"
                    value={formData.product_id}
                    onChange={handleChange}
                    required
                  >
                    {products
                      .filter((product) => product.product_type === "Pocket")
                      .map((product) => (
                        <MenuItem key={product.product_id} value={product.product_id}>
                          {product.product_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box mt={3} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#4e2a84", color: "#fff" }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Creating..." : "Create Pocket"}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>

      {/* Popup Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Pocket Creation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to create the following pocket?
          </DialogContentText>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>Pocket Name:</strong> {formData.pocket_name}
            </Typography>
            <Typography variant="body1">
              <strong>Product:</strong>{" "}
              {products.find((p) => p.product_id === formData.product_id)?.product_name || "Unknown"}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CreateSubAccount = ({ selectedAccount, products, onCreate, setPockets, setAccounts }) => (
  <AuthGuard>
    <CreateSubAccountComponent
      selectedAccount={selectedAccount}
      products={products}
      onCreate={onCreate}
      setPockets={setPockets}
      setAccounts={setAccounts} />
  </AuthGuard>

);

export default CreateSubAccount;
