import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    Stack,
} from "@mui/material";
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard
import { oauthConfig } from "../config/oauthConfig";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../utils/authUtils";
import axios from "axios";

const TransactionHistoryComponent = ({ accounts = [] }) => {
    const [selectedAccount, setSelectedAccount] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);

    const token = localStorage.getItem("access_token");
    const navigate = useNavigate();

    const handleAccountChange = (event) => {
        setSelectedAccount(event.target.value);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    // Filter transactions based on the selected account
    const filteredTransactions = transactions.filter(
        (transaction) => transaction.accountId === selectedAccount
    );

    useEffect(() => {
        if (!token || isTokenExpired(token)) {
            navigate('/logout');
        }
    }, [navigate, token]);

    useEffect(() => {
        const fetchTransactions = async () => {
            if (!selectedAccount) return; // Only fetch if there's a selected account
            try {
                // Fetch transaction data based on the selected account
                const offset = (page * 10) - 10;
                const limit = 10;

                const url = `${oauthConfig.transactionHistoryService}?limit=${limit}&offset=${offset}`
                const response = await axios.get(url.replace(":account-id", selectedAccount), {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
                setTransactions(response.data.data.transactions);
                setTotalCount(response.data.data.total_count);

                window.scrollTo(0, 0);
            } catch (error) {
                console.error("Error fetching account list:", error);
            }
        };

        fetchTransactions();
    }, [selectedAccount, page]);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                minHeight: "100vh",
                paddingTop: "50px",
            }}
        >
            <Box
                padding={2}
                style={{
                    maxWidth: "500px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="h5" gutterBottom align="center" color="primary">
                    Transaction History
                </Typography>

                {/* Dropdown to Select Account */}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Select Account</InputLabel>
                    <Select value={selectedAccount} onChange={handleAccountChange}>
                        {accounts.map((account) => (
                            <MenuItem key={account.account_id} value={account.account_number}>
                                {account.account_type} ({account.account_number})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Transaction Table */}
                {selectedAccount && filteredTransactions.length === 0
                    ? (
                        <Typography
                            variant="body1"
                            align="center"
                            color="text.secondary"
                            sx={{ marginTop: 4 }}
                        >
                            Please select an account to view its transaction history.
                        </Typography>
                    )
                    : (
                        <>
                            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredTransactions.map((transaction) => (
                                            <TableRow key={transaction.id}>
                                                <TableCell>{transaction.date}</TableCell>
                                                <TableCell>{transaction.description}</TableCell>
                                                <TableCell>{transaction.amount}</TableCell>
                                                <TableCell>{transaction.type}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack
                                spacing={2}
                                sx={{
                                    marginTop: 2.5,
                                    marginBottom: 10,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <Pagination
                                    count={Math.ceil(totalCount / 10)}
                                    onChange={handlePageChange}
                                />
                            </Stack>
                        </>
                    )}

                {!selectedAccount && (
                    <Typography
                        variant="body1"
                        align="center"
                        color="text.secondary"
                        sx={{ marginTop: 4 }}
                    >
                        Please select an account to view its transaction history.
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
const TransactionHistory = ({ accounts }) => (
    <AuthGuard>
        <TransactionHistoryComponent accounts={accounts} />
    </AuthGuard>
);
export default TransactionHistory;
