import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    MenuItem,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard
import { oauthConfig } from "../config/oauthConfig";
import { isTokenExpired } from "../utils/authUtils";
import axios from "axios";

const WithdrawComponent = ({ accounts, onWithdraw, setTransactionType, setTransactionErrorDetails }) => {
    setTransactionType("Withdraw");

    const token = localStorage.getItem("access_token");
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        account_no: "",
        amount: "",
        description: "",
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false); // For when transfer is loading
    const [withdrawDetails, setWithdrawDetails] = useState("");

    useEffect(() => {
        if (!token || isTokenExpired(token)) {
            navigate('/logout');
        }
    }, [navigate, token]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setWithdrawDetails(`Withdrawing an amount of ${formData.amount} from account ${formData.account_no}1001`);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleConfirm = async () => {
        setOpenDialog(false);
        setLoading(true);

        // Rest API call here
        try {
            const response = await axios.post(`${oauthConfig.withdrawService}`, formData, {
                headers: {
                    Authorization: "Bearer " + token,
                }
            });

            if (response.data.data.status == "POSTING_INSTRUCTION_BATCH_STATUS_REJECTED") {
                // Displaying contracts violation here
                const violationMessage = (violations) => {
                    let violationMessage = '';

                    const violationNames = Object.keys(violations);
                    violationNames.forEach(violationName => {
                        if (violations[violationName]) {
                            try {
                                const violation = JSON.parse(violations[violationName]);

                                if (violation.length > 0) {
                                    violationMessage += `${violationName}:\n`;
                                    violation.forEach((violation, index) => {
                                        violationMessage += `${index + 1}. Account ID: ${violation.account_id}\n`;
                                        violationMessage += `Reason: ${violation.reason}\n`;
                                        violationMessage += `Type: ${violation.type}\n\n`;
                                    });
                                }
                            } catch (error) {
                                console.error("Error parsing contract violations:", error);
                            }
                        }
                    });
                    return violationMessage;
                };

                setTransactionErrorDetails(`${violationMessage(response.data.data.violation)}`);

                navigate("/error");

                console.log("Withdraw fails:", response.data);
            } else if (response.data.data.status == "POSTING_INSTRUCTION_BATCH_STATUS_ACCEPTED") {
                if (onWithdraw) onWithdraw(response.data); // Call the withdraw handler if provided

                setFormData({ account_no: "", amount: "", description: "" }) // Reset the form

                navigate("/success");  // Navigate to the Success page

                console.log(`Withdraw funds successfully for ID: ${response.data.data.id}`);
            }
        } catch (error) {
            console.error("Error withdraw funds:", error);
            alert("There was an error withdraw funds. Please try again.");
        }
    };

    const filteredCasaAccounts = accounts.filter(
        (account) => (accounts.length > 0) && (account.account_type === "Savings" && account.account_status === "ACCOUNT_STATUS_OPEN" && account.parent_account_id === null)
    );

    // Checking if there are valid accounts for withdraw
    if (filteredCasaAccounts.length === 0) {
        return (
            <Box sx={{ textAlign: "center", paddingTop: "50px" }}>
                <Typography variant="h6" color="error">
                    No accounts available for withdraw.
                </Typography>
            </Box>
        );
    }

    return (
        <>
            {loading
                ? <Loading transactionType="Withdraw" details={withdrawDetails} />
                : <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        minHeight: "100vh",
                        paddingTop: "50px",
                    }}
                >
                    <Box
                        padding={4}
                        style={{
                            maxWidth: "500px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px",
                            backgroundColor: "#fff",
                        }}
                    >
                        <Typography variant="h5" gutterBottom align="center" color="primary">
                            Withdraw Funds
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {/* Select account to withdraw from */}
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Select Main Casa Account"
                                        name="account_no"
                                        value={formData.account_no}
                                        onChange={handleChange}
                                        required
                                    >
                                        {filteredCasaAccounts.map((account) => (
                                            <MenuItem key={account.account_id} value={account.account_number}>
                                                {account.account_type} ({account.account_number})
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                {/* Deposit Amount */}
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Amount"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                        required
                                        type="number"
                                        placeholder="Enter amount to withdraw"
                                    />
                                </Grid>

                                {/* Description */}
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        placeholder="Add a note (optional)"
                                    />
                                </Grid>
                            </Grid>

                            {/* Submit Button */}
                            <Box mt={3} textAlign="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ backgroundColor: "#4e2a84", color: "#fff" }}
                                >
                                    Withdraw
                                </Button>
                            </Box>
                        </form>
                    </Box>

                    {/* Dialog for Confirmation */}
                    <Dialog open={openDialog} onClose={handleDialogClose}>
                        <DialogTitle>Confirm Withdraw</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Are you sure you want to withdraw <strong>{formData.amount}</strong>{" "}
                                from your main pocket account{" "}<strong>{formData.account_no}1001</strong>{" "}(CASA {formData.account_no})?
                            </Typography>
                            {formData.description && (
                                <Typography mt={2}>
                                    <strong>Note:</strong> {formData.description}
                                </Typography>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirm} color="primary" variant="contained">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            }
        </>
    );
};

const Withdraw = ({ accounts, onWithdraw, setTransactionType, setTransactionErrorDetails }) => (
    <AuthGuard>
        <WithdrawComponent
            accounts={accounts}
            onWithdraw={onWithdraw}
            setTransactionType={setTransactionType}
            setTransactionErrorDetails={setTransactionErrorDetails}
        />
    </AuthGuard>
);

export default Withdraw;