import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard

const SuccessComponent = ({ transactionType }) => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5); // 5-second countdown

  // Countdown and auto-navigation
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      navigate("/"); // Navigate to the main dashboard after 5 seconds
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  // Manual redirection handler
  const handleManualRedirect = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        position: "relative", // Position for the close button
      }}
    >
      {/* Manual Redirect Close Button */}
      <IconButton
        sx={{
          position: "absolute",
          top: "20px",
          right: "20px",
          color: "#555",
        }}
        onClick={handleManualRedirect}
      >
        <CloseIcon />
      </IconButton>

      {/* Success Icon */}
      <CheckCircleIcon
        sx={{
          fontSize: "100px",
          color: "green",
          marginBottom: "20px",
        }}
      />

      {/* Success Message */}
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: "bold", color: "#4e2a84" }}
      >
        {transactionType} Successful!
      </Typography>

      <Typography
        variant="body1"
        align="center"
        sx={{ color: "#555", marginTop: "10px" }}
      >
        Your transaction has been completed successfully.
      </Typography>

      {/* Countdown Message */}
      <Typography
        variant="body2"
        align="center"
        sx={{ color: "#888", marginTop: "20px" }}
      >
        Redirecting to the Home page in {countdown} seconds...
      </Typography>
    </Box>
  );
};

const Success = ({ transactionType }) => (
  <AuthGuard>
    <SuccessComponent transactionType={transactionType} />
  </AuthGuard>
);

export default Success;
