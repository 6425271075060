import React from "react";
import { Box, Typography } from "@mui/material";
import { OrbitProgress } from "react-loading-indicators";
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard

const LoadingComponent = ({ transactionType, details }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minHeight: "100vh",
                backgroundColor: "transparent",
                position: "relative", // Position for the close button
                marginTop: "-100px",
            }}
        >

            {/* Loading Icon */}
            <OrbitProgress
                color="purple"
                size="medium"
                align="center"
                sx={{ fontSize: "100px", marginBottom: "20px" }}
                dense
            />

            {/* Loading Message */}
            <Typography
                variant="h4"
                align="center"
                sx={{ fontWeight: "bold", color: "#4e2a84" }}
            >
                {transactionType} Processing...
            </Typography>

            <Typography
                variant="body1"
                align="center"
                sx={{ color: "#555", marginTop: "10px" }}
            >
                Your transaction is currently being processed. Please wait a moment...
            </Typography>

            <Typography
                variant="body2"
                align="center"
                sx={{ color: "#888", marginTop: "20px" }}
            >
                Details: {details}
            </Typography>
        </Box>
    );
};

const Loading = ({ transactionType, details }) => (
    <AuthGuard>
        <LoadingComponent
            transactionType={transactionType}
            details={details}
        />
    </AuthGuard>
);

export default Loading;